table {
  width: 100%;
  margin-top: 40px;
}

button {
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin: 10px 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}
