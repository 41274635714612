.timeline-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: fit-content; */
    margin: 150px auto 7px;
  }
  
  .timeline-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--stronger-grey);
    width: 150px;
  }
  
  .timeline-container > div > .square {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--stronger-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 10px;
  }
  
  .timeline-container > .line {
    width: 300px;
    border: 1px solid var(--stronger-grey);
    background-color: var(--stronger-grey);
    margin-top: -25px;
  }
  
  .timeline-highlight > .div {
    color: var(--medium-blue);
  }