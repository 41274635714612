.user_information-details {
    height: 50px;
    width: 15%;
    margin-left: 80%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 36px;
    background-color: rgb(141, 143, 141);
    text-align: center;
  }
  .user_information {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    margin-bottom: 36px;
    white-space : nowrap;
  }
  .user_information-user_name {
    width: 104px;
    height: 25px;
    font-family: Overpass;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    /* identical to box height, or 104% */
    color: #081c4d;
  }
  /* Test User logout */
  
  /* -------------------- */
  /* ---- UserHeader ---- */
  /* -------------------- */
  
  .user-header {
    width: 100%;
    height: 60px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: static;
    padding-bottom: 5px;
    margin: -40px 0 40px 0;
  }
  
  #arrow {
    height: 20px;
    background-color: transparent;
    color: var(--background-blue);
    cursor: pointer;
    outline: 0;
    font-size: 30px;
    /* margin: 0px 30px 0 15px; */
    margin:0px 30px 20px 14px;
  }
  .user-header-area {
    display: flex;
    align-items: center;
    width: 175px;
    display: absolute;
    position: absolute;
    right: 30px;
    border-radius: 5px 5px 0 0;
  }
  
  .dropdown-user {
    position: absolute;
    background: var(--background);
    top: 70px;
    right: 30px;
    width: 175px;
    z-index: 3;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-top: 2px solid var(--dark-grey);
  }
  .dropdown-user ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
  }
  
  .disconnect-link {
    padding: 12px 12px;
    font-family: "Overpass", sans-serif;
    line-height: 25px;
    font-size: 18px;
    font-weight: 700;
  
    display: flex;
    align-items: center;
    color: rgba(8, 28, 77, 0.5);
  }
  
  .user-header-area:hover {
    cursor: pointer;
  }
  
  .disconnect-link:hover::after {
    width: 50%;
    transition: 0.1s;
  }
  .disconnect-link::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    /* background: var(--pink);
    transition: 0.6s; */
  }
  .user-header-name {
    font-size: 18px;
    font-weight: 700;
    color: var(--background-blue);
    white-space: nowrap;
    text-transform: capitalize;
  }
  .user-header-company {
    text-transform: capitalize;
    font-size: 16px;
    color: var(--dark-grey);
  }
  
  .disconnect-link > div {
    margin-left: 10px;
    cursor: pointer;
  }
  .disconnect-link > img {
    cursor: pointer;
  }
  
  .disconnect-link:hover > svg path {
    fill-opacity: inherit;
  }
  
  .disconnect-link:hover > div {
    color: var(--background-blue);
  }
  