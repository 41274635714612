.predict-pm-container {
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.form-container-90,
.form-container-60,
.form-container-40,
.form-container-30,
.form-container-70, 
.form-container-30-son {
  /* margin: auto; */
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.15);
  border-radius: 10px;
  margin-bottom: 40px;
}

.form-container-90 {
  width: 90%;
  /* height:calc(596px/3); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-container-60 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
  /* padding: inherit; */
  height: 100%;
}

.form-container-40 {
  width: 29%;
  margin-bottom: inherit;
}

.form-container-30 {
  width: 29%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-container-30-son{
  height: 170px;
  width: 30%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}
.circular-loader-container{
  height: 170px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.form-container-70 {
  width: 69%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.risk-metrics {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.risk-metrics > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.risk-metrics > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .hidden { display: none; } */

.risk-metric {
  color: #1976D2;
  font-family: "Overpass";
  font-weight: normal;
  font-size: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-end;
}

.buttons > div {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(#F9F9FF);
}

.buttons > div > span {
  color: var(--grey);
}

.filter-container{
  height:95%;
}

.radio-buttons{
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  /* background-color: red; */
  width: 500px;
}

.radio-button{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

body {
  margin: 20px;
}

/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

.css-ahj2mt-MuiTypography-root{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.highcharts-credits{
  display:none;
}