/*Importing css files */
@import "index.css";
@import "./style/Model.css";
@import "./style/Indicator.css";
@import "./style/Predict.css";
@import "./style/Dashboard.css";
@import "./style/Login.css";
@import "./style/navbar.css";
@import "./style/userProfile.css";
@import "./style/Chip.css";
@import "./style/Timeline.css";

@import "react-checkbox-tree/lib/react-checkbox-tree.css";

/*Importing Fonts(Overpass + Montserrat) */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Overpass:wght@300;400;700&display=swap");

/* ---------------- */
/* - Common Rules - */
/* ---------------- */

:root {
  --background: #e7ecef;
  --background-blue: #2D5596;
  --green: #2ec4b6;
  --dark-grey: #cbd2d0;
  --light-grey: #cbd2d0;
  --input-grey: #9b9b9b;
  --yellow: #ffce31;
  --grey:#494D58;
  /* --grey: #e5e5e5; */
  --stronger-grey: #c4c4c4;
}


*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

html{
font-size: 16px;
font-weight: 400;
letter-spacing: 0.05em;
}

body{
min-height: 100vh;
font-family: "Overpass";
display: flex;
background-color: var(--background);
overflow: hidden;
}


#root{
flex-grow:1;
display: flex;
justify-content: center;
align-items: center;
}


.App {
  width: 100%;
  height: 100vh;
}

.loader-container{
  width:100%;
  flex-grow:1;
  padding: 1rem;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-with-margin {
  margin-left: 301px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.container-without-margin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

h2{
  font-family: "Overpass";
  font-weight: normal;
  font-size: 24px;
  line-height: 37px;
}

h3{
  font-family: "Overpass";
  font-weight: normal;
  font-size: 18px;
  color:var(--background-blue);
}
.error {
  color: red;
}

button {
  cursor: pointer;
  background-color: inherit;
}

a{
  text-decoration: none;
    color: inherit;
}

.App-link {
  color: #61dafb;
}

.edit-model-container {
  width: 90%;
  height:596px;
  margin: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 15px 30px rgba(123, 123, 123, 0.15);
  border-radius: 10px;
  margin-bottom: 40px;
}
