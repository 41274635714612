.login-container {
  background-color: var(--background-blue);
    width: 100%;
    min-height: 100vh;
    display: flex;
  }
  
  .marketing-section {
    background-color: white;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .marketing-section > p {
    font-family: "Overpass", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: var(--green);
    width: 50%;
    text-align: center;
  }
  
  .login-section {
    width: 65%;
  }
  
  .form-login-bloc {
    height: 495px;
    width: 418px;
    padding: 39px 54px 39px 54px;
    box-sizing: border-box;
    margin: 100px auto;
    background: #ffffff;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.25);
  }
  
  .form-login-bloc > h2 {
    font-family: Overpass;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    color:var(--background-blue)
  }
  .form-login-bloc > form {
    margin-top: 70px;
  }
  
  .label {
    color: var(--input-grey);
  }
  
  .inputWithIcon {
    display: flex;
    align-items: center;
    outline: none;
    background-color: inherit;
  }
  
  .form-input,
  .form-input-with-icon {
    color: black;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    width: 310px;
    margin: 10px 0 15px 0;
    padding-bottom: 5px;
    background-color: inherit;
    outline: none;
    font-size: 16px;
  }
  
  .lock-icon {
    width: 17px;
    height: 21px;
    margin-bottom: 10px;
  }
  
  .signin-btn {
    margin-top: 50px;
    width: 310px;
    height: 46px;
    background-color: var(--background-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 700;
    border: none;
    outline: none;
  }
  
  .signin-btn:hover {
    opacity: 0.8;
  
  }
  
  #eye {
    cursor: pointer;
    color: --var(--btn-dark-grey);
    z-index: 1;
  }
  
  .inputIcon {
    background-color: white;
    height:100%;
  
    box-sizing: border-box;
    margin: 10px 0;
  }