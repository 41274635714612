:root {
    --navbar-background: #081c4d;
  }
  
  .navbar {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    position: sticky;
  }
  .navbar2 {
    background-color: #f5fafd;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    position: sticky;
  }
  
  .menu-bars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
  }
  .menu-bars-text {
    margin-left: 1rem;
    font-size: 1.6rem;
    background: none;
    color: #f5f5f5;
  }
  .nav-menu {
    background-color: var(--navbar-background);
    width: 301px;
    display: flex;
    justify-content: center;
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 0;
    left: -100%;
  }
  .nav-menu::-webkit-scrollbar {
    display: none;
  }
  
  .nav-menu.active {
    left: 0;
  }
  
  .nav-text {
    display: flex;
  }
  
  .nav-text a {
    text-decoration: none;
    color: rgb(46, 196, 182);
    width: 100%;
    height: 60px;
    display: flex;
  
    align-items: center;
    border-radius: 4px;
    padding-left: 40px;
  }
  
  .nav-text a:hover {
    background-color: #061437;
  }
  
  .nav-menu-items {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  
  .navbar-toggle {
    background-color: var(--navbar-background);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: start;
  }
  
  .navbar-texts {
    margin-top: 50px;
  }
  .nav-menu-user {
    display: flex;
    width: 50%;
    flex-direction: row-reverse;
    margin-right: 2rem;
    font-size: 2rem;
    background: none;
  }
  .nav-header {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    background-color: var(--background);
  }
  ul {
    list-style-type: none;
  }
  .nav-scalnyx-logo {
    width: 70%;
    display: flex;
    margin: 0 auto;
    object-fit: contain;
  }
  
  .navbar-pdfReport-label {
    font-family: Overpass;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    align-items: center;
    color: #2ec4b6;
  }
  .navbar-pdfReport-text {
    font-family: Overpass;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e5e5e5;
    margin-right: 30px;
  }
  .btn-download {
    background: #2ec4b6;
    border: 2px solid #2ec4b6;
    box-sizing: border-box;
    border-radius: 2px;
    width: 212px;
    height: 39px;
    margin-right: 50px;
  }
  .menu-bars-toggle {
    display: flex;
    width: 50%;
    margin-left: 1rem;
  }
  